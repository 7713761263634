<template>
  <div class="wallet mt-3">
    <div v-if="this.wallet.account == null">
      <div>
        <button @click="Meta_mask_connect"
          class="wallet__custom-btn"
          ><span>錢包連線</span></button
        >
        <!-- <button @click="wallet_connect"
          class="wallet__custom-btn"
          ><span>連接其他 Wallet</span></button
        > -->
      </div>
    </div>
    <div v-else-if="this.wallet.account !== null && isValidityOwner">
      <h2 class="mb-2"><i class="bi bi-wallet me-2"></i>錢包位址</h2>
      <div>
        {{ wallet.account.replace(wallet.account.substring(8, 35), '...') }}
        <span><i class="bi bi-clipboard" @click="copyUrl" style="cursor: pointer;"></i></span>
      </div>
    </div>
    <div v-else-if="this.wallet.account !== null">
      <h2 class="mb-2"><i class="bi bi-wallet me-2"></i>錢包位址</h2>
      <div>
        {{ wallet.account.replace(wallet.account.substring(8, 35), '...') }}
        <span><i class="bi bi-clipboard" @click="copyUrl" style="cursor: pointer;"></i></span>
      </div>

      <button @click="SignMessage"
        class="wallet__custom-btn"
        ><span>身分驗證</span></button
      >
      <div v-if="this.wallet.provider != null">
        <br />
        <button @click="disconnect_wallet_connect"
          class="wallet__custom-btn"
          ><span>停止連接</span></button
        >
      </div>
    </div>
  </div>
</template>

<script>
import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3 from 'web3';
import { mapGetters, mapActions } from 'vuex';
import Swal from 'sweetalert2';

export default {
  name: 'web3_connect',
  props: {
    API_URL: String,
    extra_sign_data: String,
    walletAddress: String,
    isValidityOwner: Boolean,
  },
  data() {
    return {
      wallet: {
        web3: null,
        account: null,
        provider: null,
        response: null,
      },
    };
  },
  watch: {},
  methods: {
    async Meta_mask_connect() {
      if (window.ethereum) {
        this.wallet.account = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });
        this.wallet.account = this.wallet.account[0];
        window.web3 = new Web3(window.ethereum);
        this.wallet.web3 = window.web3;

        this.$emit('update', this.wallet);
      } else {
        // 去安裝metamask
        window.open(
          'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
        );
      }
    },
    async checkConnect() {
      if (window.ethereum) {
        this.wallet.account = await window.ethereum.request({
          method: 'eth_accounts',
        });
        if (this.wallet.account.length === 0) {
          this.wallet.account = null;
        } else {
          this.wallet.account = this.wallet.account[0];
          window.web3 = new Web3(window.ethereum);
          this.wallet.web3 = window.web3;

          this.$emit('update', this.wallet);
        }
      }
    },
    async wallet_connect() {
      this.wallet.provider = new WalletConnectProvider({
        infuraId: '3a08f1931ec840c5b0de3dc0b4c98cff',
      });

      await this.wallet.provider.enable();
      this.wallet.web3 = new Web3(this.wallet.provider);
      this.wallet.account = await this.wallet.web3.eth.getAccounts();
      this.wallet.account = this.wallet.account[0];
      this.$emit('update', this.wallet);
    },
    async SignMessage() {
      this.extra_sign_data = this.extra_sign_data == null ? '測試中' : this.extra_sign_data;
      const message = JSON.stringify({
        extra_sign_data: this.extra_sign_data,
        wallet_address: this.wallet.account,
      });
      const secret = await this.wallet.web3.eth.personal.sign(
        message,
        this.wallet.account,
        '',
      );

      const data = { message, signature: secret };
      // console.log(data);
      // api 參數
      this.API_URL = this.API_URL == null
        ? `${this.$API_PATH}/Member/Web3`
        : this.API_URL;
      // console.log(this.API_URL);
      const response = await fetch(this.API_URL, {
        body: JSON.stringify(data),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      });
      this.wallet.response = await response.json();
      // console.log(this.wallet.response);
      if (this.wallet.response.status == 'Success') {
        Swal.fire(
          '',
          `${this.wallet.response.message}`,
          'success',
        );
      }
      if (this.wallet.response.status == 'Failed') {
        Swal.fire(
          '',
          '驗證失敗',
          'info',
        );
      }

      this.$emit('update', this.wallet);
      this.$emit('getUser');
    },
    async disconnect_wallet_connect() {
      await this.wallet.provider.disconnect();
      this.wallet.provider = null;
      this.wallet.web3 = null;
      this.wallet.account = null;
      this.$emit('update', this.wallet);
    },
    copyUrl() {
      const el = document.createElement('textarea');
      el.value = this.wallet.account;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
      this.$emit('toast');
      // console.log(this.walletAddress);
    },
    ...mapActions(['getUserAuth', 'getUserAuthFromLocal', 'checkExpAuth']),
  },
  computed: {
    ...mapGetters(['userName', 'Avatar', 'userId', 'role', 'exp', 'userAuth', 'LoginProvider']),
  },
  mounted() {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', async () => {
        this.wallet.account = await window.ethereum.request({
          method: 'eth_accounts',
        });
        if (this.wallet.account.length === 0) {
          this.wallet.account = null;
        } else {
          this.wallet.account = this.wallet.account[0];
          window.web3 = new Web3(window.ethereum);
          this.wallet.web3 = window.web3;
          this.$emit('update', this.wallet);
        }
      });
    }
  },
  watch: {
    walletAddress(newVal, oldVal) {
      if (!this.walletAddress) {
        this.wallet.account = null;
        this.checkConnect();
        // console.log('w');
      } else {
        this.wallet.account = this.walletAddress;
        this.$emit('update', this.wallet);
        // console.log('3');
      }
    },
  },
  created() {
    this.getUserAuthFromLocal();
    this.checkExpAuth();
    // console.log(this.walletAddress);
    if (!this.walletAddress) {
      this.wallet.account = null;
      this.checkConnect();
    } else {
      this.wallet.account = this.walletAddress;
      this.$emit('update', this.wallet);
    }
    // console.log(this.wallet.account)
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
